import { isValidPhoneNumber } from 'libphonenumber-js';
import { FormErrorsProps, FormValueProps } from '../interfaces/interfaces';
import countries from './countries';

const validation = ({
  //1
  companyName,
  //companyStartDate,
  companyEmail,
  phoneNumber,

  //2
  address1,
  address2,
  city,
  state,
  zipCode,
  country,

  //3
  hasLocalOrOffice,
  workingHours,
  wantToHaveWebPage,
  missionVision,
  productOrService,
  whyAClientShouldChooseOurServices,
  companyObjectives,
  staffAmount,
  briefCompanyHistory,
  industrySector,

  //4
  //Informacion de la marca
  yearsOfExperience,
  valuesThatIdentifyTheBrand,
  competitorDirectOrIndirectOfTheBrand,
  diferenceBetwenYourBrandAndCompetitors,
  technologyImplemented,
  strengths,
  opportunities,
  weaknesses,
  threatsOfTheCompany,

  //5
  //consumer
  idealConsumerGender,
  actualPrincipalConsumerGender,
  idealConsumerAge,
  idealConsumerSocioEconomicLvl,
  //
  idealConsumerLocationisNacional,
  idealConsumerLocationisAmericaLatina,
  idealConsumerLocationisNorteAmerica,
  idealConsumerLocationisAmericaYEuropa,
  idealConsumerLocationisAmericaYAsia,
  idealConsumerLocationisInternacional,

  aditionalInfo,
}: //6
FormValueProps) => {
  const errors: FormErrorsProps = {
    //1
    companyName: '',
    companyStartDate: '',
    companyEmail: '',
    phoneNumber: '',

    //2
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',

    //3
    hasLocalOrOffice: '',
    workingHours: '',
    wantToHaveWebPage: '',
    missionVision: '',
    productOrService: '',
    whyAClientShouldChooseOurServices: '',
    companyObjectives: '',
    staffAmount: '',
    briefCompanyHistory: '',
    industrySector: '',

    //4
    //Informacion de la marca
    yearsOfExperience: '',
    valuesThatIdentifyTheBrand: '',
    competitorDirectOrIndirectOfTheBrand: '',
    diferenceBetwenYourBrandAndCompetitors: '',

    //5
    //consumer
    idealConsumerGender: '',
    actualPrincipalConsumerGender: '',
    idealConsumerAge: '',
    idealConsumerLocation: '',
    idealConsumerSocioEconomicLvl: '',
    //6

    //Informacion Detallada De La Marca
    technologyImplemented: '',
    strengths: '',
    opportunities: '',
    weaknesses: '',
    threatsOfTheCompany: '',
    //

    aditionalInfo: '',
  };

  //Validation for name
  if (!companyName.trim()) {
    errors.companyName = 'Este campo es requerido';
  } else if (companyName.trim() && companyName.trim().length < 3) {
    errors.companyName = 'Minimo debe escribir 3 caracteres';
  } else {
    errors.companyName = '';
  }

  //Validation for email
  if (!companyEmail.trim()) {
    errors.companyEmail = 'Este campo es requerido';
  } else if (
    companyEmail.trim() &&
    !companyEmail
      .trim()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    errors.companyEmail = 'Use un correo correcto';
  } else {
    errors.companyEmail = '';
  }

  //phoneNumber
  if (!phoneNumber.trim()) {
    errors.phoneNumber = 'Este campo es requerido';
  } else if (phoneNumber.trim() && !isValidPhoneNumber(phoneNumber)) {
    errors.phoneNumber = 'Use un numero de telefono correcto';
  } else {
    errors.phoneNumber = '';
  }

  //address 1
  if (!address1.trim()) {
    errors.address1 = 'Este campo es requerido';
  } else if (address1.trim() && address1.trim().length < 3) {
    errors.address1 = 'Minimo debe escribir 3 caracteres';
  } else {
    errors.address1 = '';
  }

  //address 2
  if (address2.trim() && address2.trim().length < 3) {
    errors.address2 = 'Minimo debe escribir 3 caracteres';
  } else {
    errors.address2 = '';
  }

  //city
  if (city.trim() && city.trim().length < 3) {
    errors.city = 'Minimo debe escribir 3 caracteres';
  } else {
    errors.city = '';
  }

  //state
  if (state.trim() && state.trim().length < 3) {
    errors.state = 'Minimo debe escribir 3 caracteres';
  } else {
    errors.state = '';
  }

  //zipcode
  if (zipCode.trim() && !/^[0-9\- ]*$/.test(zipCode.trim())) {
    errors.zipCode = 'Solo numero y guiones son permitidos';
  } else {
    errors.zipCode = '';
  }

  //country
  if (!country.trim()) {
    errors.country = 'Este campo es requerido';
  } else if (
    !!!countries.find(
      (item) => item.name === country.replace(/\b[a-z]/g, (char) => char.toUpperCase())
    )
  ) {
    errors.country = 'Elija un nombre de pais valido';
  } else {
    errors.country = '';
  }

  //staff amount
  if (!staffAmount.trim()) {
    errors.staffAmount = 'Este campo es requerido';
  } else {
    errors.staffAmount = '';
  }

  //staff amount
  if (!staffAmount.trim()) {
    errors.staffAmount = 'Este campo es requerido';
  } else {
    errors.staffAmount = '';
  }

  // Cuenta con local o oficina
  if (!hasLocalOrOffice.trim()) {
    errors.hasLocalOrOffice = 'Este campo es requerido';
  } else {
    errors.hasLocalOrOffice = '';
  }

  // Horario de Trabajo
  if (!workingHours.trim()) {
    errors.workingHours = 'Este campo es requerido';
  } else {
    errors.workingHours = '';
  }

  // Le gustaria una pagina web?
  if (!wantToHaveWebPage.trim()) {
    errors.wantToHaveWebPage = 'Este campo es requerido';
  } else {
    errors.wantToHaveWebPage = '';
  }

  if (!missionVision.trim()) {
    //mission vision
    errors.missionVision = 'Este campo es requerido';
  } else {
    errors.missionVision = '';
  }

  //product or service
  if (!productOrService.trim()) {
    errors.productOrService = 'Este campo es requerido';
  } else {
    errors.productOrService = '';
  }

  // Porque deberian los clientes elegir sus servicios
  if (!whyAClientShouldChooseOurServices.trim()) {
    errors.whyAClientShouldChooseOurServices = 'Este campo es requerido';
  } else {
    errors.whyAClientShouldChooseOurServices = '';
  }

  // Objetivos de la compania
  if (!companyObjectives.trim()) {
    errors.companyObjectives = 'Este campo es requerido';
  } else {
    errors.companyObjectives = '';
  }

  if (!briefCompanyHistory.trim()) {
    //company history
    errors.briefCompanyHistory = 'Este campo es requerido';
  } else {
    errors.briefCompanyHistory = '';
  }

  //industry sector
  if (!industrySector.trim()) {
    errors.industrySector = 'Este campo es requerido';
  } else {
    errors.industrySector = '';
  }

  if (!yearsOfExperience) {
    errors.yearsOfExperience = 'Este campo es requerido';
  } else {
    errors.yearsOfExperience = '';
  }

  //fiverWordsDescribeBrand
  if (!valuesThatIdentifyTheBrand.trim()) {
    errors.valuesThatIdentifyTheBrand = 'Este campo es requerido';
  } else {
    errors.valuesThatIdentifyTheBrand = '';
  }

  //competencia direct o indirecta de la marca
  if (!competitorDirectOrIndirectOfTheBrand.trim()) {
    errors.competitorDirectOrIndirectOfTheBrand = 'Este campo es requerido';
  } else {
    errors.competitorDirectOrIndirectOfTheBrand = '';
  }

  //diferenceBetwenYourBrandAndCompetitors
  if (!diferenceBetwenYourBrandAndCompetitors.trim()) {
    errors.diferenceBetwenYourBrandAndCompetitors = 'Este campo es requerido';
  } else {
    errors.diferenceBetwenYourBrandAndCompetitors = '';
  }

  if (!idealConsumerGender.trim()) {
    errors.idealConsumerGender = 'Este campo es requerido';
  } else {
    errors.idealConsumerGender = '';
  }

  if (!actualPrincipalConsumerGender.trim()) {
    errors.actualPrincipalConsumerGender = 'Este campo es requerido';
  } else {
    errors.actualPrincipalConsumerGender = '';
  }

  if (!idealConsumerAge.trim()) {
    errors.idealConsumerAge = 'Este campo es requerido';
  } else {
    errors.idealConsumerAge = '';
  }

  const idealConsumerLocation = {
    idealConsumerLocationisNacional,
    idealConsumerLocationisAmericaLatina,
    idealConsumerLocationisNorteAmerica,
    idealConsumerLocationisAmericaYEuropa,
    idealConsumerLocationisAmericaYAsia,
    idealConsumerLocationisInternacional,
  };

  const noOneIsPick = Object.values(idealConsumerLocation).every((location) => location === false);

  if (noOneIsPick) {
    errors.idealConsumerLocation = 'Este campo es requerido';
  } else {
    errors.idealConsumerLocation = '';
  }

  if (!idealConsumerSocioEconomicLvl.trim()) {
    errors.idealConsumerSocioEconomicLvl = 'Este campo es requerido';
  } else {
    errors.idealConsumerSocioEconomicLvl = '';
  }

  if (!technologyImplemented.trim()) {
    errors.technologyImplemented = 'Este campo es requerido';
  } else {
    errors.technologyImplemented = '';
  }

  if (!strengths.trim()) {
    errors.strengths = 'Este campo es requerido';
  } else {
    errors.strengths = '';
  }

  if (!opportunities.trim()) {
    errors.opportunities = 'Este campo es requerido';
  } else {
    errors.opportunities = '';
  }

  if (!weaknesses.trim()) {
    errors.weaknesses = 'Este campo es requerido';
  } else {
    errors.weaknesses = '';
  }

  if (!threatsOfTheCompany.trim()) {
    errors.threatsOfTheCompany = 'Este campo es requerido';
  } else {
    errors.threatsOfTheCompany = '';
  }
  //

  if (aditionalInfo.trim() && aditionalInfo.trim().length < 6) {
    errors.aditionalInfo = 'Minimo debe escribir 6 caracteres';
  } else {
    errors.aditionalInfo = '';
  }

  return errors;
};

export default validation;
