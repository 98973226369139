import { useId } from 'react';
import {
  FormDataList,
  FormInput,
  FormPhoneInput,
  FormTextArea,
  RegularButton,
  FormRadio,
  FormSelect,
} from 'infrastructure/components';
import {
  consumerIdealAge,
  countries,
  formErrors,
  formValues,
  staffAmount,
  yearsOfExperience,
} from './utils';
import { useForm } from './hooks';

import { BeatLoader } from 'react-spinners';
import * as S from './BriefForm.styled';
import moment from 'moment';

const BriefForm = () => {
  const inputId = useId();

  const {
    values,
    errors,
    disableSubmit,
    isValidating,
    isLoading,
    handleSubmit,
    phoneNumberHandleChange,
    inputHandleChange,
    textAreaHandleChange,
    selectHandleChange,
  } = useForm(formValues, formErrors);

  return (
    <S.Form onSubmit={handleSubmit}>
      {/**
       * CAMPOS DE INFORMACION BASICOS
       */}
      <S.FieldSet>
        <S.FieldLegend>Información básica de la compañía</S.FieldLegend>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-companyNameInput`}
            name='companyName'
            type='text'
            placeholder='Compañía'
            onChange={inputHandleChange}
            value={values.companyName}
            required
            formInputProps={{
              label: 'Nombre de la compañía *',
              errorMessage: errors.companyName,
              withCheck: true,
              isValidating,
            }}
          />
          <FormInput
            id={`${inputId}-dateFoundedInput`}
            name='companyStartDate'
            max={moment().subtract(1, 'days').format('YYYY-MM-DD')}
            type='date'
            onChange={inputHandleChange}
            value={values.companyStartDate}
            formInputProps={{
              label: 'Fecha en que fue fundada',
              errorMessage: errors.companyStartDate,
              withCheck: false,
              isValidating,
            }}
          />
        </S.InputsContainer>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-emailInput`}
            name='companyEmail'
            type='email'
            placeholder='email@email.com'
            onChange={inputHandleChange}
            value={values.companyEmail}
            required
            formInputProps={{
              label: 'Correo *',
              errorMessage: errors.companyEmail,
              withCheck: true,
              isValidating,
            }}
          />
          <FormPhoneInput
            id={`${inputId}-phoneInput`}
            name='phoneNumber'
            placeholder='1 (234) 567-8910'
            formInputProps={{
              label: 'Número de teléfono *',
              errorMessage: errors.phoneNumber,
              withCheck: true,
              onChange: phoneNumberHandleChange,
              value: values.phoneNumber || '',
              isValidating,
            }}
          />
        </S.InputsContainer>
      </S.FieldSet>
      <S.FieldSet>
        <S.FieldLegend>Información de ubicación</S.FieldLegend>
        <FormInput
          id={`${inputId}-address1Input`}
          name='address1'
          type='text'
          placeholder='Indica sector/calle/edificio'
          onChange={inputHandleChange}
          value={values.address1}
          formInputProps={{
            label: 'Dirección 1 *',
            errorMessage: errors.address1,
            withCheck: true,
            isValidating,
          }}
        />
        <FormInput
          id={`${inputId}-address2Input`}
          name='address2'
          type='text'
          placeholder='Indica sector/calle/edificio'
          onChange={inputHandleChange}
          value={values.address2}
          formInputProps={{
            label: 'Dirección 2',
            errorMessage: errors.address2,
            withCheck: true,
            isValidating,
          }}
        />
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-cityInput`}
            name='city'
            type='text'
            placeholder='Barcelona'
            onChange={inputHandleChange}
            value={values.city}
            formInputProps={{
              label: 'Ciudad',
              errorMessage: errors.city,
              withCheck: true,
              isValidating,
            }}
          />
          <FormInput
            id={`${inputId}-stateInput`}
            name='state'
            type='text'
            placeholder='Anzoátegui'
            onChange={inputHandleChange}
            value={values.state}
            formInputProps={{
              label: 'Estado',
              errorMessage: errors.state,
              withCheck: true,
              isValidating,
            }}
          />
        </S.InputsContainer>
        <S.InputsContainer>
          <FormInput
            id={`${inputId}-zipCodeInput`}
            name='zipCode'
            type='text'
            placeholder='0230'
            onChange={inputHandleChange}
            value={values.zipCode}
            formInputProps={{
              label: 'Código De Area',
              errorMessage: errors.zipCode,
              withCheck: false,
              isValidating,
            }}
          />

          <FormDataList
            id={`${inputId}-countryInput`}
            name='country'
            type='text'
            placeholder='Venezuela (VE)'
            onChange={inputHandleChange}
            value={values.country}
            required
            formInputProps={{
              options: countries,
              label: 'País *',
              errorMessage: errors.country,
              withCheck: true,
              isValidating,
            }}
          />
        </S.InputsContainer>
      </S.FieldSet>
      <S.Division />

      {/**
       * CAMPOS DE INFORMACION CUSTOMS
       */}

      <S.FieldSet>
        <S.FieldLegend>Información detallada de la empresa</S.FieldLegend>
        <FormSelect
          id={`${inputId}-staffAmountInput`}
          name='staffAmount'
          onChange={selectHandleChange}
          value={values.staffAmount}
          required
          formSelectProps={{
            errorMessage: errors.staffAmount,
            label: 'Cuantas personas trabajan en la empresa: *',
            options: staffAmount,
            withCheck: false,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-workingHours`}
          name='workingHours'
          onChange={textAreaHandleChange}
          value={values.workingHours}
          required
          formTextAreaProps={{
            errorMessage: errors.workingHours,
            label: '¿Cuál es su horario de atención al público? (EJ. LUNES A VIERNES 8AM-5PM) *',
            withCheck: true,
            isValidating,
          }}
        />

        <S.Label>¿Tiene su empresa algún local u oficina?: *</S.Label>
        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-hasLocalOrOfficeYes`,
              label: 'SÍ',
              name: 'hasLocalOrOffice',
              radioValue: 'yes',
              value: values.hasLocalOrOffice,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-hasLocalOrOfficeNo`,
              label: 'NO',
              name: 'hasLocalOrOffice',
              radioValue: 'no',
              value: values.hasLocalOrOffice,
              handleChange: inputHandleChange,
            }}
          />
          <S.Error>{errors.hasLocalOrOffice}</S.Error>
        </S.RadioCont>

        <FormTextArea
          id={`${inputId}-briefCompanyHistoryInput`}
          name='briefCompanyHistory'
          onChange={textAreaHandleChange}
          value={values.briefCompanyHistory}
          required
          formTextAreaProps={{
            errorMessage: errors.briefCompanyHistory,
            label: 'Breve descripción de la empresa: *',
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-industrySectorInput`}
          name='industrySector'
          onChange={textAreaHandleChange}
          value={values.industrySector}
          required
          formTextAreaProps={{
            errorMessage: errors.industrySector,
            label: '¿En qué tipo de industria en específico trabaja? *',
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-missionInput`}
          name='missionVision'
          onChange={textAreaHandleChange}
          value={values.missionVision}
          required
          formTextAreaProps={{
            errorMessage: errors.missionVision,
            label: 'Misión y visión: *',
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-companyObjectivesInput`}
          name='companyObjectives'
          onChange={textAreaHandleChange}
          value={values.companyObjectives}
          required
          formTextAreaProps={{
            errorMessage: errors.companyObjectives,
            label: 'Objetivos de la empresa: *',
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-productOrServiceInput`}
          name='productOrService'
          onChange={textAreaHandleChange}
          value={values.productOrService}
          required
          formTextAreaProps={{
            errorMessage: errors.productOrService,
            label: '¿Qué servicios ofrece su empresa actualmente? *',
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-whyAClientShouldChooseOurServicesInput`}
          name='whyAClientShouldChooseOurServices'
          onChange={textAreaHandleChange}
          value={values.whyAClientShouldChooseOurServices}
          required
          formTextAreaProps={{
            errorMessage: errors.whyAClientShouldChooseOurServices,
            label: '¿Por qué considera que un potencial cliente debería elegir sus servicios? *',
            withCheck: true,
            isValidating,
          }}
        />

        <S.Label>¿Le gustaría poseer página web?: *</S.Label>
        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-wantToHaveWebPageYes`,
              label: 'SÍ',
              name: 'wantToHaveWebPage',
              radioValue: 'yes',
              value: values.wantToHaveWebPage,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-wantToHaveWebPageNo`,
              label: 'NO',
              name: 'wantToHaveWebPage',
              radioValue: 'no',
              value: values.wantToHaveWebPage,
              handleChange: inputHandleChange,
            }}
          />
          <S.Error>{errors.wantToHaveWebPage}</S.Error>
        </S.RadioCont>
      </S.FieldSet>
      <S.Division />

      {/**
       * Que identifica a la marca
       */}
      <S.FieldSet>
        <S.FieldLegend>Información de la Marca</S.FieldLegend>
        <FormSelect
          id={`${inputId}-yearsOfExperienceInput`}
          name='yearsOfExperience'
          onChange={selectHandleChange}
          value={values.yearsOfExperience}
          required
          formSelectProps={{
            errorMessage: errors.yearsOfExperience,
            label: '¿Cuantos años de desarrollo o experiencia lleva actualmente en la industria? *',
            options: yearsOfExperience,
            withCheck: false,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-valuesThatIdentifyTheBrandInput`}
          name='valuesThatIdentifyTheBrand'
          onChange={textAreaHandleChange}
          value={values.valuesThatIdentifyTheBrand}
          required
          formTextAreaProps={{
            errorMessage: errors.valuesThatIdentifyTheBrand,
            label: 'Valores que identifican la marca: *',
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-competitorDirectOrIndirectOfTheBrandInput`}
          name='competitorDirectOrIndirectOfTheBrand'
          onChange={textAreaHandleChange}
          value={values.competitorDirectOrIndirectOfTheBrand}
          required
          formTextAreaProps={{
            errorMessage: errors.competitorDirectOrIndirectOfTheBrand,
            label: '¿Cuál considera que es su competencia directa o indirecta? *',
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-diferenceBetwenYourBrandAndCompetitorsInput`}
          name='diferenceBetwenYourBrandAndCompetitors'
          onChange={textAreaHandleChange}
          value={values.diferenceBetwenYourBrandAndCompetitors}
          required
          formTextAreaProps={{
            label: '¿Cuál es la propuesta única de valor? Factor diferencial de la competencia: *',
            errorMessage: errors.diferenceBetwenYourBrandAndCompetitors,
            withCheck: true,
            isValidating,
          }}
        />

        <FormTextArea
          id={`${inputId}-technologyImplementedInput`}
          name='technologyImplemented'
          onChange={textAreaHandleChange}
          value={values.technologyImplemented}
          formTextAreaProps={{
            label:
              '¿Utiliza algun programa o sistema administrativo para las actividades operativas de la empresa? CRM, ERP, entre otros: *',
            errorMessage: errors.technologyImplemented,
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-strengthsInput`}
          name='strengths'
          onChange={textAreaHandleChange}
          value={values.strengths}
          formTextAreaProps={{
            label: 'Fortalezas: *',
            errorMessage: errors.strengths,
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-opportunitiesInput`}
          name='opportunities'
          onChange={textAreaHandleChange}
          value={values.opportunities}
          formTextAreaProps={{
            label: 'Oportunidades: *',
            errorMessage: errors.opportunities,
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-weaknessesInput`}
          name='weaknesses'
          onChange={textAreaHandleChange}
          value={values.weaknesses}
          formTextAreaProps={{
            label: 'Debilidades: *',
            errorMessage: errors.weaknesses,
            withCheck: true,
            isValidating,
          }}
        />
        <FormTextArea
          id={`${inputId}-threatsOfTheCompanyInput`}
          name='threatsOfTheCompany'
          onChange={textAreaHandleChange}
          value={values.threatsOfTheCompany}
          formTextAreaProps={{
            label: 'Amenazas de la marca o empresa: *',
            errorMessage: errors.threatsOfTheCompany,
            withCheck: true,
            isValidating,
          }}
        />
      </S.FieldSet>
      <S.Division />
      <S.FieldSet>
        <S.FieldLegend>Información del consumidor</S.FieldLegend>
        <S.Label>¿Género del consumidor ideal?: *</S.Label>

        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerGenderM`,
              label: 'Masculino',
              name: 'idealConsumerGender',
              radioValue: 'Masculino',
              value: values.idealConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerGenderF`,
              label: 'Femenino',
              name: 'idealConsumerGender',
              radioValue: 'Femenino',
              value: values.idealConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerGenderA`,
              label: 'Ambos',
              name: 'idealConsumerGender',
              radioValue: 'Ambos',
              value: values.idealConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <S.Error>{errors.idealConsumerGender}</S.Error>
        </S.RadioCont>

        <S.Label>¿De que genero se compone principalmente el consumidor?: *</S.Label>
        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-actualPrincipalConsumerGenderM`,
              label: 'Masculino',
              name: 'actualPrincipalConsumerGender',
              radioValue: 'Masculino',
              value: values.actualPrincipalConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-actualPrincipalConsumerGenderF`,
              label: 'Femenino',
              name: 'actualPrincipalConsumerGender',
              radioValue: 'Femenino',
              value: values.actualPrincipalConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-actualPrincipalConsumerGenderA`,
              label: 'Ambos',
              name: 'actualPrincipalConsumerGender',
              radioValue: 'Ambos',
              value: values.actualPrincipalConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-actualPrincipalConsumerGenderO`,
              label: 'Otros',
              name: 'actualPrincipalConsumerGender',
              radioValue: 'Otros',
              value: values.actualPrincipalConsumerGender,
              handleChange: inputHandleChange,
            }}
          />
          <S.Error>{errors.actualPrincipalConsumerGender}</S.Error>
        </S.RadioCont>

        <FormSelect
          id={`${inputId}-idealConsumerAge`}
          name='idealConsumerAge'
          onChange={selectHandleChange}
          value={values.idealConsumerAge}
          required
          formSelectProps={{
            errorMessage: errors.idealConsumerAge,
            label: 'Edad del consumidor ideal: *',
            options: consumerIdealAge,
            withCheck: false,
            isValidating,
          }}
        />

        <S.Label>Ubicación del consumidor ideal: *</S.Label>
        <S.CheckBoxCont>
          <S.InputsContainer>
            <FormInput
              id={`${inputId}-idealConsumerLocationNacional`}
              name='idealConsumerLocationisNacional'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisNacional}
              formInputProps={{
                label: 'Nacional',
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-idealConsumerLocationisAmericaLatina`}
              name='idealConsumerLocationisAmericaLatina'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisAmericaLatina}
              formInputProps={{
                label: 'América Latina',
                withCheck: false,
                isValidating,
              }}
            />

            <FormInput
              id={`${inputId}-idealConsumerLocationisNorteAmerica`}
              name='idealConsumerLocationisNorteAmerica'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisNorteAmerica}
              formInputProps={{
                label: 'Norteamérica',
                withCheck: false,
                isValidating,
              }}
            />
          </S.InputsContainer>
          <S.InputsContainer>
            <FormInput
              id={`${inputId}-idealConsumerLocationisAmericaYEuropa`}
              name='idealConsumerLocationisAmericaYEuropa'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisAmericaYEuropa}
              formInputProps={{
                label: 'América Y Europa',
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-idealConsumerLocationisAmericaYAsia`}
              name='idealConsumerLocationisAmericaYAsia'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisAmericaYAsia}
              formInputProps={{
                label: 'América Y Asia',
                withCheck: false,
                isValidating,
              }}
            />
            <FormInput
              id={`${inputId}-idealConsumerLocationisInternacional`}
              name='idealConsumerLocationisInternacional'
              type='checkbox'
              onChange={inputHandleChange}
              checked={values.idealConsumerLocationisInternacional}
              formInputProps={{
                label: 'Internacional',
                withCheck: false,
                isValidating,
              }}
            />
          </S.InputsContainer>
          <S.Error $isCheckBox>{errors.idealConsumerLocation}</S.Error>
        </S.CheckBoxCont>

        <S.Label>Nivel socieconómico del consumidor ideal: *</S.Label>
        <S.RadioCont>
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerSocioEconomicLvlA`,
              label: 'Clase alta',
              name: 'idealConsumerSocioEconomicLvl',
              radioValue: 'Clase alta',
              value: values.idealConsumerSocioEconomicLvl,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerSocioEconomicLvlM`,
              label: 'Clase media',
              name: 'idealConsumerSocioEconomicLvl',
              radioValue: 'Clase media',
              value: values.idealConsumerSocioEconomicLvl,
              handleChange: inputHandleChange,
            }}
          />
          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerSocioEconomicLvlB`,
              label: 'Clase baja',
              name: 'idealConsumerSocioEconomicLvl',
              radioValue: 'Clase baja',
              value: values.idealConsumerSocioEconomicLvl,
              handleChange: inputHandleChange,
            }}
          />

          <FormRadio
            formRadioProps={{
              id: `${inputId}-idealConsumerSocioEconomicLvlT`,
              label: 'Todas Las Anteriores',
              name: 'idealConsumerSocioEconomicLvl',
              radioValue: 'Todas (baja, media, alta)',
              value: values.idealConsumerSocioEconomicLvl,
              handleChange: inputHandleChange,
            }}
          />
          <S.Error>{errors.idealConsumerSocioEconomicLvl}</S.Error>
        </S.RadioCont>
      </S.FieldSet>

      <S.Division />
      <S.FieldSet>
        <FormTextArea
          id={`${inputId}-aditionalInfoInput`}
          name='aditionalInfo'
          onChange={textAreaHandleChange}
          value={values.aditionalInfo}
          formTextAreaProps={{
            errorMessage: errors.aditionalInfo,
            label: 'Información adicional sobre la marca o empresa:',
            withCheck: true,
            isValidating,
          }}
        />
      </S.FieldSet>

      {isLoading ? (
        <S.SpinnerCont>
          <BeatLoader color='#ffffff50' margin={3} speedMultiplier={0.6} />
        </S.SpinnerCont>
      ) : (
        <RegularButton
          regularButtonProps={{ color: 'orange', text: 'ENVIAR' }}
          disabled={disableSubmit}
          type='submit'
        />
      )}
    </S.Form>
  );
};

export default BriefForm;
