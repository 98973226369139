import { FormValueProps, FormErrorsProps } from '../interfaces';

export const formValues: FormValueProps = {
  //1
  companyName: '',
  companyStartDate: '',
  companyEmail: '',
  phoneNumber: '',

  //2
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',

  //3
  hasLocalOrOffice: 'no',
  workingHours: '',
  wantToHaveWebPage: 'no',
  missionVision: '',
  productOrService: '',
  whyAClientShouldChooseOurServices: '',
  companyObjectives: '',
  briefCompanyHistory: '',
  staffAmount: '',
  industrySector: '',

  //4
  //Informacion de la marca
  yearsOfExperience: 0,
  valuesThatIdentifyTheBrand: '',
  competitorDirectOrIndirectOfTheBrand: '',
  diferenceBetwenYourBrandAndCompetitors: '',
  technologyImplemented: '',
  strengths: '',
  opportunities: '',
  weaknesses: '',
  threatsOfTheCompany: '',
  aditionalInfo: '',

  //5
  //consumer
  idealConsumerGender: '',
  actualPrincipalConsumerGender: '',
  idealConsumerAge: '',
  idealConsumerSocioEconomicLvl: '',
  //
  idealConsumerLocationisNacional: false,
  idealConsumerLocationisAmericaLatina: false,
  idealConsumerLocationisNorteAmerica: false,
  idealConsumerLocationisAmericaYEuropa: false,
  idealConsumerLocationisAmericaYAsia: false,
  idealConsumerLocationisInternacional: false,
  //6
};

export const formErrors: FormErrorsProps = {
  //1
  companyName: '',
  companyStartDate: '',
  companyEmail: '',
  phoneNumber: '',

  //2
  address1: '',
  address2: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',

  //3
  hasLocalOrOffice: '',
  workingHours: '',
  wantToHaveWebPage: '',
  missionVision: '',
  productOrService: '',
  whyAClientShouldChooseOurServices: '',
  companyObjectives: '',
  staffAmount: '',
  briefCompanyHistory: '',
  industrySector: '',

  //4
  //Informacion de la marca
  yearsOfExperience: '',
  valuesThatIdentifyTheBrand: '',
  competitorDirectOrIndirectOfTheBrand: '',
  diferenceBetwenYourBrandAndCompetitors: '',
  technologyImplemented: '',
  strengths: '',
  opportunities: '',
  weaknesses: '',
  threatsOfTheCompany: '',
  aditionalInfo: '',

  //5
  //consumer
  idealConsumerGender: '',
  actualPrincipalConsumerGender: '',
  idealConsumerAge: '',
  idealConsumerLocation: '',
  idealConsumerSocioEconomicLvl: '',
  //6
};
